import React, { useMemo } from "react";
import { useState } from "react";
import { Loader, NoDataFound, Pagination, SearchInput } from "../Common";
import { useCallback } from "react";
import { useEffect } from "react";
import { useAuth } from "../../context/useAuth";
import UsersTable from "./UsersTable";
import { getUsers } from "../../utils/userUtils/userUtils";
import _ from "lodash";

const limit = 10;

const UsersMainSection = () => {
   const user = useAuth().authState.user;
  const [searchQuery, setSearchQuery] = useState("");
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    nextPage: null,
    prevPage: null,
    totalPages: 1,
  });
  const fetchUsers = useCallback(async (search, page = 1) => {
    setLoading(true);
    try {
      const response = await getUsers(true, search, page, "/getuserbydbdashboard");
      const { data: users, pagination } = response?.data?.results;
      setDatas(users);
      setPagination(pagination);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const debouncedFetchUsers = useMemo(
    () =>
      _.debounce(async (search, page = 1) => {
        await fetchUsers(search, page);
      }, 300),
    [fetchUsers]
  );

  useEffect(() => {
    debouncedFetchUsers(searchQuery, pagination?.currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, debouncedFetchUsers, pagination?.currentPage]);

  useEffect(() => {
    debouncedFetchUsers(searchQuery, 1);
  }, [debouncedFetchUsers, searchQuery]);

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, currentPage: newPage }));
  };
  const handleSearchQueryChange = (newSearchQuery) => {
    const value = newSearchQuery;
    if (value.length === 1 && value === " ") {
      return;
    }
    const modifiedValue = value.replace(/\s+$/, " ");
    setSearchQuery(modifiedValue);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
    debouncedFetchUsers(modifiedValue, 1);
  };
  return (
    <>
      <div className=" flex w-full items-center gap-5 flex-col">
        <div className="flex w-fit h-fit text-xl font-semibold flex-shrink-0">
          Registered Users
        </div>
        <div className="flex w-full h-full justify-center items-center">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search"}
              searchQuery={searchQuery}
              setSearchQuery={handleSearchQueryChange}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full p-3 flex-col">
        <div className="flex w-full justify-start items-center h-fit">
          <span className="font-semibold text-base">Informations</span>
        </div>
        {loading ? (
          <div className="flex mt-10">
            <Loader />
          </div>
        ) : datas?.length > 0 ? (
          <>
            <UsersTable
              datas={datas}
              setDatasFn={() => {
                fetchUsers(searchQuery, pagination?.currentPage);
              }}
              user={user}
              currentPage={pagination?.currentPage}
              limit={limit}
            />
            <Pagination
              current={pagination?.currentPage}
              total={pagination?.totalPages}
              onPageChange={handlePageChange}
            />
          </>
        ) : (
          <div className="flex mt-10">
            <NoDataFound data={"users"} />
          </div>
        )}
      </div>
    </>
  );
};

export default UsersMainSection;
