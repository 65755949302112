import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { useForm } from "../../hooks";
import { TextInput } from "../Common/FormComponents";
import { toast } from "react-toastify";
import { addSalesBD } from "../../utils/salesUtils/salesUtils";

const emailPattern = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
const phoneNumberPattern = /^(?!0{10}$)\d{10}$/;

const SalesDBModal = ({
  setOpen,
  editData = null,
  setEditData = () => {},
  setDatasFn,
}) => {
  const initialState = {
    name: editData?.name || "",
    region: editData?.region || "",
    designation: editData?.designation || "",
    email_id: editData?.email_id || "",
    phone_number: editData?.phone_number?.toString() || "",
    department: editData?.department || "",
  };
  const [formData, handleFormData, clearForm] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState({ global: "" });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.name.trim().length) {
      setErr({ global: "Invalid Name" });
      return;
    }
    if (!formData.region.trim().length) {
      setErr({ global: "Invalid Region" });
      return;
    }
    if (!emailPattern.test(formData.email_id)) return setErr({ global: "Invalid Email" });
    if (!phoneNumberPattern.test(formData.phone_number)) return setErr({ global: "Invalid Phone" });
    if (!formData.department.trim().length) {
      setErr({ global: "Invalid Department" });
      return;
    }
    if (!formData.designation.trim().length) {
      setErr({ global: "Invalid Designation " });
      return;
    }
    setErr({ global: "" });
    setLoading(true);
    addSalesBD(formData, editData?._id)
      .then((res) => {
        setDatasFn();
        clearForm();
        setEditData(null);
        setOpen(false);
        toast(`BD ${editData ? `Edited` : `Added`}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(`BD ${editData ? `Edit` : `Add`} Failed`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex  w-full max-w-screen-md overflow-y-auto max-h-screen  border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{editData ? "Edit BD" : "Add New BD"}</span>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </button>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"name"}
            name={"Name"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            formName={"region"}
            name={"Region"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"email_id"}
            name={"Email"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            formName={"phone_number"}
            name={"Phone"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"department"}
            name={"Department"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            formName={"designation"}
            name={"Designation"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {loading ? "Please Wait..." : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesDBModal;
