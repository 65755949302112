import React from "react";
import UsersMainSection from "../../components/BdUsersComponents/UsersMainSection";

const BdUsers = () => {
  return (
    <div className=" w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative">
      <UsersMainSection/>
    </div>
  );
};

export default BdUsers;
